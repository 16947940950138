@import url(sanitize.css);
@font-face {
  font-family: "DIN Next Slab W01 Light";
  src: url("/assets/fonts/f1211723-b9df-4bb1-b0f6-c3d50d0c0c3f.eot?#iefix");
  src: url("/assets/fonts/f1211723-b9df-4bb1-b0f6-c3d50d0c0c3f.eot?#iefix") format("eot"), url("/assets/fonts/02d5d753-9a15-429f-9c1f-913d5496437e.woff2") format("woff2"), url("/assets/fonts/55cbf539-8ef3-42c6-b9a9-6a231b8312a7.woff") format("woff"), url("/assets/fonts/375cbc07-2011-4643-8405-e2b9d8b3ad57.ttf") format("truetype"), url("/assets/fonts/4c7930e1-f8ba-476c-877d-1f7b280564fb.svg#4c7930e1-f8ba-476c-877d-1f7b280564fb") format("svg"); }

:root {
  --font-system-ui: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

body {
  font-family: var(--font-system-ui); }

img {
  max-width: 100%; }

iframe {
  width: 100%; }

article.content {
  max-width: 50%;
  margin: 0 auto; }
  @media screen and (max-width: 600px) {
    article.content {
      max-width: none;
      margin: 0 1rem; } }

.asset-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: "DIN Next Slab W01 Light"; }
  @supports (display: grid) {
    .asset-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 2rem; } }

.asset-item {
  float: left;
  max-width: 30%;
  margin-right: 3%;
  margin-bottom: 3%; }
  @supports (display: grid) {
    .asset-item {
      max-width: none;
      margin: 0; } }
  .asset-item .image img {
    width: 100%; }
  .asset-item .asset-info {
    background-color: #d9d9d6;
    display: flex;
    justify-content: space-between;
    padding-left: 27px;
    position: relative; }
  .asset-item .rights {
    font-size: 11px;
    color: #63666a;
    text-transform: uppercase; }
  .asset-item .download {
    background-color: #cf4520;
    background-image: url("/assets/download-icon.png");
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999vw;
    width: 50px; }
  .asset-item.font-dark .asset-title {
    color: #ffffff; }

.asset-title {
  margin: 0;
  position: absolute;
  bottom: 58px;
  font-family: "DIN Next Slab W01 Light";
  font-size: 21px;
  line-height: 24px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
  margin-left: -27px;
  padding-left: 27px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: -27px;
  width: 100%; }

.lightbox-target {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  text-align: center;
  z-index: 999;
  display: none; }

.lightbox-target img {
  margin-top: 2%;
  width: auto !important;
  height: auto !important;
  max-height: 90%; }
  .lightbox-target img[data-orientation="horizontal"] {
    max-height: 90%; }
  .lightbox-target img[data-orientation="vertical"] {
    max-width: 90%; }

/* Styles the close link, adds the slide down transition */
a.lightbox-close {
  display: block;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: white;
  color: black;
  text-decoration: none;
  position: absolute;
  top: -80px;
  right: 0;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

/* Provides part of the "X" to eliminate an image from the close link */
a.lightbox-close:before {
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: black;
  position: absolute;
  left: 26px;
  top: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

/* Provides part of the "X" to eliminate an image from the close link */
a.lightbox-close:after {
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: black;
  position: absolute;
  left: 26px;
  top: 10px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */
.lightbox-target:target {
  outline: none;
  display: block; }

.lightbox-target:target a.lightbox-close {
  top: 0px; }
